
import { defineComponent, nextTick, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: { TmTooltip, TmScrollbar, TmButton },
  setup() {
    const testing = ref(false)
    const samples = [
      ['top-left', 'top', 'top-right'],
      ['left-top', 'right-top'],
      ['left', 'right'],
      ['left-bottom', 'right-bottom'],
      ['bottom-left', 'bottom', 'bottom-right'],
    ]
    const getContent = (sample: string): string => {
      switch (sample) {
        case 'top':
        case 'right':
        case 'bottom':
        case 'left':
          return sample
        case 'left-top':
        case 'left-bottom':
        case 'right-top':
        case 'right-bottom':
          return 'Lorem ipsum dolor sit amet, consectetur adipisicing aliquid aspernatur elit.'
        default:
          return 'Some text as content of Tooltip'
      }
    }

    const scroll = ref()
    const centering = () => {
      const { scrollWidth, offsetWidth, scrollHeight, offsetHeight } = scroll.value.$el
      scroll.value.$el.scrollLeft = (scrollWidth - offsetWidth) / 2
      scroll.value.$el.scrollTop = (scrollHeight - offsetHeight) / 2
    }

    const toggleTesting = async () => {
      testing.value = !testing.value
      await nextTick()
      centering()
    }

    return {
      testing,
      samples,
      scroll,
      toggleTesting,
      getContent,
    }
  },
})
